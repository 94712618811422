import { cva } from 'class-variance-authority'

export { default as Dialog } from './Dialog.client.vue'
export { default as DialogTrigger } from './DialogTrigger.vue'
export { default as DialogHeader } from './DialogHeader.vue'
export { default as DialogBody } from './DialogBody.vue'
export { default as DialogClose } from './DialogClose.vue'
export { default as DialogContent } from './DialogContent.vue'
export { default as DialogFooter } from './DialogFooter.vue'

export const dialogContentVariants = cva(
  'fixed left-1/2 top-1/2 z-[110] grid max-h-[90dvh] w-full -translate-x-1/2 -translate-y-1/2 grid-rows-[auto_minmax(0,1fr)_auto] rounded-xl bg-white-950 p-0 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
  {
    variants: {
      size: {
        lg: 'max-w-7xl',
        md: 'max-w-3xl',
        sm: 'max-w-lg'
      }
    }
  }
)

export const dialogEventPropKeys = [
  'closeAutoFocus',
  'escapeKeyDown',
  'focusOutside',
  'interactOutside',
  'openAutoFocus',
  'pointerDownOutside'
] as const
